import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Layout, Modal, Input, FormInstance, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";

import { useStores } from "store";
import { catchFlowCancel } from "utils";
import { useDebounced } from "utils/useDebounced";
import { L } from "modules/L18n";
import { authenticationStore } from "modules/Auth";
import { IIdentifiable } from "core/types";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { DetailObject, Objects } from "entities/Objects";

import "./Sider.styles.less";
import ObjectRow from "./elements/ObjectRow/ObjectRow";
import CreateOrUpdateObject from "./elements/CreateOrUpdateObject";
import EventsSider from "./elements/EventsSider";

const useObjectsCount = (store: BaseCrudStore<Objects>) => {
  const [count, setCount] = useState(store.count || 0);

  const debouncedFn = useDebounced(() => {
    if (!store.filters.search && !store.isEntitiesLoading) {
      setCount(store.count);
    }
  }, 300);

  useEffect(debouncedFn, [
    store.count,
    store.filters.search,
    store.isEntitiesLoading,
  ]);

  return count;
};

const { confirm } = Modal;

const Sider = ({ show }: { show?: boolean }) => {
  const { mainStore, objectStore, eventStore } = useStores();

  const selectedObjectId = objectStore.selectedObject?.id;

  const [modalOpen, setModalOpen] = useState(false);
  const [objectId, setObjectId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [search, setSearch] = useState("");

  const objectsCount = useObjectsCount(objectStore);

  const { pathname } = useLocation();

  const infiniteScrollRef = useRef<any>(null);
  const formRef = useRef<FormInstance>();

  const toggleModalOpen = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const createOrUpdateModalOpen = async (entity: IIdentifiable) => {
    if (entity.id === 0) {
      objectStore.createEntity();
    } else {
      await objectStore.get(entity.id);
    }

    setIsLoading(true);
    await objectStore.loadEmployees();
    setIsLoading(false);

    toggleModalOpen();

    setObjectId(entity.id);

    const entityTyped = objectStore.entity as unknown as DetailObject;

    formRef.current?.setFieldsValue({
      ...entityTyped,
      regional_representative: entityTyped?.regional_representative?.map(
        (employee) => employee.id
      ),
      contract: entityTyped?.contract?.id,
      group: entityTyped?.group?.id,
      so_executor: entityTyped?.so_executor?.id,
      head_of_security_id: entityTyped?.head_of_security_id?.id,
    });
  };

  const handleCreate = () => {
    const form = formRef.current;

    form
      ?.validateFields()
      .then(async (values: any) => {
        if (objectId === 0) {
          const entity = await objectStore.create(values);
          objectStore.entities = [entity, ...objectStore.entities];
        } else {
          if (typeof values.photo === "string") {
            //prevent upload links
            delete values.photo;
          }
          await objectStore.update({ ...values, id: objectId });
        }

        await objectStore.refresh();

        setModalOpen(false);
      })
      .catch();
  };

  const selectObject = (object: Objects) => {
    if (selectedObjectId === object.id) {
      objectStore.selectObject(null);
      hideObjectEvents();
    } else {
      objectStore.selectObject(object);
      showObjectEvents();
      infiniteScrollRef.current?.scrollTo?.(0, 0);
    }
  };

  const showObjectEvents = async () => {
    mainStore.toggleEventSidebar(true);
  };

  const hideObjectEvents = () => {
    mainStore.toggleEventSidebar(false);
  };

  const remove = (entityId: IIdentifiable["id"]) => {
    confirm({
      title: L("OBJECT_DELETE_CONFIRM"),
      okText: L("MODAL_DELETE_BUTTON"),
      cancelText: L("MODAL_CANCEL_BUTTON"),
      okButtonProps: { danger: true },
      onOk() {
        objectStore.delete(entityId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onCollapse = (collapsed: boolean) => {
    mainStore.sidebarCollapsed = collapsed;
    setCollapsed(collapsed);
  };

  const debouncedSearchCahnge = useDebounced((e: any) => {
    objectStore.setFilters({ search: e.target.value }, true);
  }, 1000);

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    debouncedSearchCahnge(e);
  };

  useEffect(() => {
    objectStore.clear();
    objectStore.setLimit(30);
    objectStore.setFilters({ isGuarded: "false", ordering: "name" });
  }, [eventStore, objectStore]);

  useEffect(() => {
    return catchFlowCancel(objectStore.loadMore(true)).cancel;
  }, [objectStore, objectStore.filters]);

  if (!show) {
    return null;
  }

  return (
    <>
      <Layout.Sider
        collapsible
        collapsed={mainStore.sidebarCollapsed}
        onCollapse={onCollapse}
        className="sidebar iguard-object-sider"
        width={310}
        collapsedWidth={227}
        style={{ marginTop: 1 }}
      >
        <p style={{ margin: 10 }}>
          Объекты: {objectsCount}
          <br />
          Пользователи онлайн: {mainStore.onlineCount}
        </p>

        <Input
          allowClear
          value={search}
          placeholder={L("FILTER_PLACEHOLDER")}
          onChange={handleSearch}
        />

        {authenticationStore.user?.is_staff && (
          <Button
            type="link"
            onClick={() => createOrUpdateModalOpen({ id: 0 })}
          >
            <PlusOutlined />
            <span>{L("OBJECT_SIDER_ADDNEWLABEL")}</span>
          </Button>
        )}

        <InfiniteScroll
          className="ant-menu ant-menu-dark ant-menu-root ant-menu-inline"
          loader={null}
          dataLength={objectStore.entities.length}
          next={() => objectStore.loadMore()}
          hasMore={objectStore.hasMore}
          height="calc(100vh - 185px)"
          style={{
            background: "transparent",
          }}
        >
          <List
            rowKey="id"
            loading={objectStore.isEntitiesLoading}
            itemLayout="horizontal"
            dataSource={objectStore.entities}
            renderItem={(object) => {
              const isSelected = object.id === selectedObjectId;

              return (
                <ObjectRow
                  key={object.id}
                  object={object}
                  isSelected={isSelected}
                  collapsed={collapsed}
                  onClick={() => selectObject(object)}
                  onEdit={() => createOrUpdateModalOpen(object)}
                  onDelete={() => remove(object.id)}
                />
              );
            }}
          />
        </InfiniteScroll>
      </Layout.Sider>

      {pathname.startsWith("/objects/monitoring") && (
        <EventsSider
          open={mainStore.eventSidebarOpen}
          onClose={hideObjectEvents}
        />
      )}

      <CreateOrUpdateObject
        formRef={(form: FormInstance) => {
          formRef.current = form;
        }}
        open={modalOpen}
        loading={isLoading}
        onCancel={() => {
          setModalOpen(false);
        }}
        onOk={handleCreate}
        obj={objectStore.entity}
      />
    </>
  );
};

export default observer(Sider);
