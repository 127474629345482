export * from "api/models/EventComment";
export * from "api/models/EventFile";
export * from "api/models/DetailEvent";

export type { EventList as Event } from "api/models/EventList";

export enum EventTypeEnum {
  TASK_REPORT = "Отчет по задаче",
  PROMPT_REPORT = "Оперативное донесение",
  REPORT = "Оперативное информирование",
  INFO = "Доклад",
  ACT = "Акт",
  ALARM = "Тревога",
  MESSAGE = "Сообщение",
}

export interface EventTargetTyped extends EventTarget {
  addEventListener(
    type: EventTypeEnum,
    callback: (evt: Event) => void | null,
    options?: AddEventListenerOptions | boolean
  ): void;
  removeEventListener(
    type: EventTypeEnum,
    callback: EventListenerOrEventListenerObject | null,
    options?: EventListenerOptions | boolean
  ): void;
}
